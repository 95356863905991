// app/javascript/controllers/custom_select_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option", "input"];

  connect() {
    this.selectedValue = this.inputTarget.value || null;
    this.updateLabel();
  }

  selectOption(event) {
    const selectedKey = event.currentTarget.dataset.value;

    event.currentTarget.classList.add("bg-red-500");

    // Update hidden input value
    this.inputTarget.value = selectedKey;

    // Store the selected value
    this.selectedValue = selectedKey;

    this.updateLabel();
  }

  updateLabel() {
    if (this.selectedValue) {
      this.optionTargets.forEach(
        (option) => {
          if (option.dataset.value === this.selectedValue) {
            option.classList.add("bg-red-500");
          } else {
            option.classList.remove("bg-red-500");
          }
      });
    }
  }
}
