import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    // After 10 seconds, call the deleteDiv function
    setTimeout(() => {
      this.deleteDiv();
    }, 10000);
  }

  deleteDiv() {
    // Remove the connected div
    this.element.remove();
  }
}