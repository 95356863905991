// app/javascript/controllers/form_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["step", "nextButton", "input", "error"];

  connect() {
    this.currentStep = 0;
    this.showStep(this.currentStep);
  }

  disconnect() {
    this.observer.disconnect();
  }

  showStep(index) {
    this.stepTargets.forEach((step, i) => {
      step.classList.toggle("hidden", i !== index);
    });

    // Show/hide buttons based on the step index
    this.toggleButtons();
  }

  nextStep() {
    if (this.currentStep < this.stepTargets.length - 1) {
      if (this.validateStep(this.currentStep)) {
        this.errorTargets[this.currentStep].classList.add("hidden");
        this.currentStep++;
        this.showStep(this.currentStep);
      } else {
        this.errorTargets[this.currentStep].classList.remove("hidden");
      }
    }
  }

  validateStep(index) {
    const input = this.inputTargets[index];

    return input.value !== "";
  }

  previousStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
      this.showStep(this.currentStep);
    }
  }

  toggleButtons() {
    const prevButton = this.element.querySelector("[data-action='click->form#previousStep']");
    const nextButton = this.element.querySelector("[data-action='click->form#nextStep']");
    const submitButton = this.element.querySelector("[type='submit']");
    
    if (this.currentStep === 0) {
      prevButton.classList.add("hidden");
    } else {
      prevButton.classList.remove("hidden");
    }

    if (this.currentStep === this.stepTargets.length - 1) {
      nextButton.classList.add("hidden");
      submitButton.classList.remove("hidden");
    } else {
      nextButton.classList.remove("hidden");
      submitButton.classList.add("hidden");
    }
  }   
}
