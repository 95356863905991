import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = ["submit"]

  change(event) {
    this.submitTargets.forEach((element) => {
      console.log(element.id)
      if (element.id == `submit-${event.target.value}` ) {
        element.classList.remove("hidden")
      } else {
        element.classList.add("hidden")
      }
    });
  }
}